import HomePageHero from './components/HomePageHero';
import {ButtonColor} from '../../lib/components/design_system/Button/options';
import SignupCTAButton from '../../lib/components/design_system/Button/SignupCTAButton';
import useAppTheme from '../../lib/components/design_system/hooks/useAppTheme';
import DefaultPageLayout from '../../lib/components/layout/DefaultPageLayout';
import SectionAboutAlliz from '../../lib/components/marketing/SectionAboutAlliz';
import SectionAudience from '../../lib/components/marketing/SectionAudience';
import SectionFAQ from '../../lib/components/marketing/SectionFAQ';
import SectionFinalCTA from '../../lib/components/marketing/SectionFinalCTA';
import SectionGetStarted from '../../lib/components/marketing/SectionGetStarted';
import SectionOffering from '../../lib/components/marketing/SectionOffering';
import SectionUpcomingEvent from '../../lib/components/marketing/SectionUpcomingEvent';
import SectionUsers from '../../lib/components/marketing/SectionUsers';
import homeUsers from '../../lib/components/user/raw/homeUsers';

import {css} from '@emotion/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function HomePage(): JSX.Element {
  const {spacing} = useAppTheme();
  const {t} = useTranslation();

  return (
    <DefaultPageLayout cta={<SectionFinalCTA />}>
      <HomePageHero />
      <SectionAboutAlliz />
      <SectionOffering />
      <SectionUpcomingEvent />
      <SectionAudience />
      <SectionUsers
        count={4}
        subtitle={t('pages.home.section-member-highlight-subtitle')}
        title={t('pages.home.section-member-highlight-title')}
        users={homeUsers}
      >
        <div
          css={css({
            marginTop: spacing.x60,
            textAlign: 'center',
          })}
        >
          <SignupCTAButton
            color={ButtonColor.ACCENT}
            guestLabel={t('pages.home.section-member-highlight-cat-label')}
            loggedInLabel={t(
              'pages.my-page.discover.my-page-discover-page-title',
            )}
            next="/mypage/discover"
          />
        </div>
      </SectionUsers>
      <SectionGetStarted />
      <SectionFAQ />
    </DefaultPageLayout>
  );
}
