import React from 'react';

export default function SectionAudiencePatternRight(): JSX.Element {
  return (
    <svg
      fill="none"
      height="372"
      viewBox="0 0 416 372"
      width="416"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M162.836 -69.1214C106.814 -58.8149 60.3869 -17.3587 34.4031 32.1149C8.40784 81.6 0.598883 138.355 0.0230012 193.936C-0.621986 256.454 13.7175 328.738 70.1078 358.496C123.066 386.447 187.438 364.144 246.523 352.813C311.46 340.367 381.809 341.126 437.866 306.813C500.821 268.268 531.493 188.726 517.752 117.477C504.012 46.2403 450.109 -14.575 382.961 -45.7017C315.836 -76.8053 236.687 -82.7062 162.836 -69.1214Z"
        fill="#F3FBFA"
      />
    </svg>
  );
}
