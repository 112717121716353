import SectionContainer from './SectionContainer';
import {linkToMarketing} from '../../url/linkToMarketing';
import Button from '../design_system/Button';
import {ButtonColor, ButtonSize} from '../design_system/Button/options';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';
import TitleText from '../design_system/typography/TitleText';
import Image from '../Image/Image';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function SectionAboutAlliz(): JSX.Element {
  const {t} = useTranslation();
  const {spacing} = useAppTheme();
  const hideBelowLarge = css({
    [useMediaQuery(MediaQuery.SmallAndMedium)]: {
      display: 'none',
    },
  });
  return (
    <SectionContainer>
      <div
        css={css({
          columnGap: 92,
          display: 'grid',
          gridTemplateColumns: '1fr minmax(300px, 5fr) minmax(300px, 5fr) 1fr',
          alignItems: 'center',
          justifyItems: 'center',
          rowGap: spacing.x32,

          [useMediaQuery(MediaQuery.SmallAndMedium)]: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          },
        })}
        data-testid="about-section"
      >
        <div css={hideBelowLarge} />
        <div
          css={css({
            width: '100%',
            boxSizing: 'border-box',
            paddingLeft: spacing.x12,
            paddingRight: spacing.x12,
          })}
        >
          <Image
            src="/static/images/about-alliz-photo.png"
            sx={css({background: 'none'})}
            width="100%"
          />
        </div>

        <Flex
          sx={css({
            flexDirection: 'column',
            gap: spacing.x32,
            justifyContent: 'center',
            paddingLeft: spacing.x12,
            paddingRight: spacing.x12,
          })}
        >
          <TitleText size="XXL">
            {t('pages.home.section-about-alliz-description')}
          </TitleText>

          <Button
            color={ButtonColor.ACCENT}
            label={t('pages.about.about', {brand: t('common.brand')})}
            size={ButtonSize.LARGE}
            to={linkToMarketing('/about')}
          />
        </Flex>
        <div css={hideBelowLarge} />
      </div>
    </SectionContainer>
  );
}
