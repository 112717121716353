import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQueryGetter, {
  MediaQuery,
} from '../design_system/hooks/useMediaQueryGetter';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Typography, {Size} from '../design_system/typography/Typography';
import Image from '../Image/Image';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly descriptiton: string;
  readonly imageUrl: string;
  readonly stepNumber: number;
  readonly title: string;
}

export default function GetStartedStep(props: Props): JSX.Element {
  const {descriptiton, imageUrl, stepNumber, title} = props;
  const {palettes, spacing} = useAppTheme();
  const getMQ = useMediaQueryGetter();
  return (
    <div
      css={css({
        alignItems: 'center',
        justifyItems: 'center',
        display: 'grid',
        columnGap: 60,
        rowGap: spacing.x20,
        gridTemplateColumns: '1fr 1fr',

        [getMQ(MediaQuery.MediumAndLarge)]: {
          gridTemplateColumns: '1fr 2fr',
          columnGap: spacing.x20,
        },

        [getMQ(MediaQuery.ExtraSmall)]: {
          gridTemplateColumns: '1fr',
        },
      })}
    >
      <Image
        src={imageUrl}
        sx={css({
          [getMQ(MediaQuery.NonSmallScreen)]: {
            gridColumn: '1 / span 1',
          },
        })}
        width="100%"
      />

      <Flex
        sx={css({
          flexDirection: 'column',
          rowGap: spacing.x16,
          [getMQ(MediaQuery.NonSmallScreen)]: {
            alignItems: 'flex-start',
            gridColumn: '2 / span 1',
          },
        })}
      >
        <Flex
          sx={css({
            borderRadius: '50%',
            justifyContent: 'center',
            height: 48,
            width: 48,
            backgroundColor: palettes.background.neutralContrast.default,
          })}
        >
          <Typography
            color={ContentColor.PRIMARY_CONTRAST}
            size={Size.L}
          >
            {stepNumber}
          </Typography>
        </Flex>

        <Typography
          color={ContentColor.PRIMARY_CONTRAST}
          css={css({
            textAlign: 'center',
          })}
          size={Size.L}
          variant="div"
          weight={700}
        >
          {title}
        </Typography>

        <Typography
          color={ContentColor.PRIMARY_CONTRAST}
          rootStyle={css({
            textAlign: 'left',
            [getMQ(MediaQuery.ExtraSmall)]: {
              textAlign: 'center',
            },
          })}
          size={Size.M}
        >
          {descriptiton}
        </Typography>
      </Flex>
    </div>
  );
}
