import useAppTheme from '../design_system/hooks/useAppTheme';
import Typography, {Size} from '../design_system/typography/Typography';
import Image from '../Image/Image';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly borderColorName: 'pink' | 'blue';
  readonly intro: string;
  readonly photoURL: string;
  readonly title: string;
  readonly subtitle?: string;
}

export default function SampleAudienceCard(props: Props): JSX.Element {
  const {borderColorName, intro, photoURL, subtitle, title} = props;
  const {spacing} = useAppTheme();

  let borderColor;
  switch (borderColorName) {
    case 'pink':
      borderColor = '#FFC4DC';
      break;
    case 'blue':
      borderColor = '#C3E9E5';
  }

  return (
    <Flex
      sx={css({
        gap: spacing.x28,
      })}
    >
      <Flex
        sx={css({
          flex: '0 0',
          flexDirection: 'column',
          gap: spacing.x8,
          height: '100%',
          justifyContent: 'center',
          textAlign: 'center',
        })}
      >
        <Image
          height={72}
          src={photoURL}
          sx={css({
            borderRadius: '50%',
          })}
          width={72}
        />

        <Typography
          size={Size.XS}
          weight={700}
        >
          {title}
        </Typography>

        {subtitle != null && <Typography size={Size.XS}>{subtitle}</Typography>}
      </Flex>

      <div
        css={css({
          '&::before': {
            backgroundColor: '#FFFFFF',
            border: `solid ${borderColor}`,
            borderWidth: '0 0 5px 5px',
            content: '""',
            height: 12,
            position: 'absolute',
            top: '50%',
            transform: 'rotate(45deg) translate(-46px)',
            width: 12,
          },
          backgroundColor: '#FFFFFF',
          border: `5px solid ${borderColor}`,
          borderRadius: '12px',
          boxSizing: 'border-box',
          padding: spacing.x20,
          position: 'relative',
        })}
      >
        <Typography size={Size.L}>{intro}</Typography>
      </div>
    </Flex>
  );
}
