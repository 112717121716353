import OfferingCard from './OfferingCard';
import SectionContainer from './SectionContainer';
import SectionTitle from './SectionTitle';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';
import ResponsiveStackColumns from '../layout/ResponsiveStackColumns';

import {css} from '@emotion/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function SectionOffering(): JSX.Element {
  const {t} = useTranslation();
  const {palettes} = useAppTheme();

  return (
    <div
      css={css({
        backgroundColor: palettes.background.neutral.disabled,
      })}
      data-testid="offering-section"
    >
      <SectionContainer>
        <SectionTitle
          subTitle={t('pages.home.section-offering-subtitle')}
          title={t('pages.home.section-offering-title')}
        />
        <ResponsiveStackColumns
          count={3}
          minWidth="250px"
          stackMediaQuery={useMediaQuery(MediaQuery.SmallAndMedium)}
        >
          <OfferingCard
            ctaLabel={t('pages.home.section-offering-mentorship-cta-label')}
            ctaUrl="/community"
            description={t(
              'pages.home.section-offering-mentorship-description',
            )}
            imageUrl="/static/images/offering-mentorship.svg"
            title={t('pages.home.section-offering-mentorship-title')}
          />
          <OfferingCard
            ctaLabel={t('pages.home.section-offering-event-cta-label')}
            ctaUrl="/event"
            description={t('pages.home.section-offering-event-description')}
            imageUrl="/static/images/offering-event.svg"
            title={t('pages.home.section-offering-event-title')}
          />
          <OfferingCard
            ctaLabel={t('pages.home.section-offering-community-cta-label')}
            ctaUrl="/community-rules"
            description={t('pages.home.section-offering-community-description')}
            imageUrl="/static/images/offering-community.svg"
            title={t('pages.home.section-offering-community-title')}
          />
        </ResponsiveStackColumns>
      </SectionContainer>
    </div>
  );
}
