import {UpcomingEvent_GroupSessionFragment} from './__generated__/MarketingFragments.graphql';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQueryGetter, {
  MediaQuery,
} from '../design_system/hooks/useMediaQueryGetter';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Typography, {Size} from '../design_system/typography/Typography';
import EventCardLocationInfo from '../event/EventCardLocationInfo';
import EventCardTimeInfo from '../event/EventCardTimeInfo';
import EventExtraInfo from '../event/EventExtraInfo';
import EventTitleWithModifier from '../event/EventTitleWIthModifier';
import Flex from '../layout/flex/Flex';
import SquareMonthDate from '../time/SquareMonthDate';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly session: UpcomingEvent_GroupSessionFragment;
}

export default function UpcomingEventCard(props: Props): JSX.Element {
  const {session} = props;
  const extraData = EventExtraInfo[session.id];
  const {palettes, spacing} = useAppTheme();
  const getMQ = useMediaQueryGetter();
  const stackedMQ = getMQ(MediaQuery.SmallScreen);

  return (
    <div
      css={css({
        display: 'grid',
        columnGap: spacing.gridColumnGap,
        rowGap: spacing.x16,
        gridTemplateColumns: 'repeat(7, 1fr)',
        gridTemplateRows: 'auto auto',

        [stackedMQ]: {
          gridTemplateRows: 'repeat(3, auto)',
          justifyItems: 'center',
        },
      })}
    >
      <div
        css={css({
          gridColumn: '1 / span 2',
          gridRow: '1 / span 2',
          display: 'flex',
          backgroundImage: `url(${extraData?.thumbnailURL ?? ''})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',

          [stackedMQ]: {
            gridColumn: '1 / span 7',
            gridRow: '1 / span 1',
            width: '100%',
            paddingBottom: '62.5%',
          },
        })}
      />

      <div
        css={css({
          gridColumn: '3 / span 5',
          gridRow: '1 / span 1',

          [stackedMQ]: {
            gridColumn: '1 / span 7',
            gridRow: '2 / span 1',
          },
        })}
      >
        <Typography
          color={ContentColor.PRIMARY_CONTRAST}
          rootStyle={css({
            [stackedMQ]: {
              textAlign: 'center',
            },
          })}
          size={Size.M}
        >
          <EventTitleWithModifier groupSession={session} />
        </Typography>
      </div>

      <Flex
        sx={css({
          gridColumn: '3 / span 5',
          gridRow: '2 / span 1',
          gap: spacing.x12,

          [stackedMQ]: {
            gridColumn: '1 / span 7',
            gridRow: '3 / span 1',
          },
        })}
      >
        <SquareMonthDate
          rootStyle={css({
            backgroundColor: palettes.background.subAccent2.default,
          })}
          size={60}
          timestampUTCSeconds={session.startTimeUTCSeconds}
        />

        <div
          css={css({
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))',
            width: '100%',
          })}
        >
          <EventCardTimeInfo
            color={ContentColor.PRIMARY_CONTRAST}
            data={session}
          />

          <EventCardLocationInfo
            color={ContentColor.PRIMARY_CONTRAST}
            data={session.venue}
          />
        </div>
      </Flex>
    </div>
  );
}
