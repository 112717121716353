import GetStartedStep from './GetStartedStep';
import ClientOnlyContent from '../ClientOnlyContent';
import SignupCTAButton from '../design_system/Button/SignupCTAButton';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';
import Typography, {Size} from '../design_system/typography/Typography';

import {css} from '@emotion/react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

export default function SectionGetStarted(): JSX.Element {
  const {t} = useTranslation();
  const {palettes, sizes, spacing} = useAppTheme();

  return (
    <div
      css={css({
        display: 'grid',
        alignItems: 'flex-start',
        gridTemplateColumns: '1fr 1fr',
        height: '100%',
        width: '100%',

        [useMediaQuery(MediaQuery.SmallScreen)]: {
          gridTemplateColumns: '1fr',
        },
      })}
      data-testid="get-started-section"
    >
      <div
        css={css({
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flex: '1 1 auto',
          backgroundImage: 'linear-gradient(to right, #FF3A8B, #FFBA4A)',
          paddingLeft: spacing.x20,
          paddingRight: spacing.x20,
          position: 'sticky',
          top: sizes.l.topNavHeight,
          height: `calc(100vh - ${sizes.l.topNavHeight}px)`,

          [useMediaQuery(MediaQuery.SmallScreen)]: {
            position: 'initial',
            height: 250,
          },
          [useMediaQuery(MediaQuery.MediumScreen)]: {
            top: sizes.md.topNavHeight,
            height: `calc(100vh - ${sizes.md.topNavHeight}px)`,
          },
        })}
      >
        <Typography
          fontFamily="GARAMOND"
          size={Size.PAGE_TITLE}
          weight={700}
        >
          <Trans i18nKey="pages.home.section-get-started-title" />
        </Typography>
      </div>

      <div
        css={css({
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          rowGap: 300,
          flex: '1 1 auto',
          backgroundColor: palettes.background.neutral.default,
          padding: '300px 80px 300px 80px',

          [useMediaQuery(MediaQuery.MediumAndLarge)]: {
            rowGap: 150,
            padding: '150px 40px 150px 40px',
          },

          [useMediaQuery(MediaQuery.SmallScreen)]: {
            rowGap: 100,
            padding: '100px 20px 100px 20px',
          },
        })}
      >
        <GetStartedStep
          descriptiton={t(
            'pages.home.section-get-started-step-one-description',
          )}
          imageUrl="/static/images/home-steps-1.svg"
          stepNumber={1}
          title={t('pages.home.section-get-started-step-one-title')}
        />

        <GetStartedStep
          descriptiton={t(
            'pages.home.section-get-started-step-two-description',
          )}
          imageUrl="/static/images/home-steps-2.svg"
          stepNumber={2}
          title={t('pages.home.section-get-started-step-two-title')}
        />
        <GetStartedStep
          descriptiton={t(
            'pages.home.section-get-started-step-three-description',
          )}
          imageUrl="/static/images/home-steps-3.svg"
          stepNumber={3}
          title={t('pages.home.section-get-started-step-three-title')}
        />
        <GetStartedStep
          descriptiton={t(
            'pages.home.section-get-started-step-four-description',
          )}
          imageUrl="/static/images/home-steps-4.svg"
          stepNumber={4}
          title={t('pages.home.section-get-started-step-four-title')}
        />

        <ClientOnlyContent>
          <SignupCTAButton
            style={css({
              width: 200,
              backgroundImage: 'linear-gradient(to right, #FF3A8B, #FFBA4A)',
            })}
          />
        </ClientOnlyContent>
      </div>
    </div>
  );
}
