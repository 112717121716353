import SampleAudienceCard from './SampleAudienceCard';
import SectionAudiencePatternLeft from './SectionAudiencePatternLeft';
import SectionAudiencePatternRight from './SectionAudiencePatternRight';
import SectionContainer from './SectionContainer';
import SectionTitle from './SectionTitle';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';

import {css} from '@emotion/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function SectionAudience(): JSX.Element {
  const {t} = useTranslation();
  const {spacing} = useAppTheme();
  const stackedMQ = useMediaQuery(MediaQuery.NonExtraLargeScreen);
  const extraLargeSideGap = (
    <div
      css={css({
        [useMediaQuery(MediaQuery.NonExtraLargeScreen)]: {
          display: 'none',
        },
      })}
    />
  );

  const largeGap = (
    <div
      css={css({
        display: 'none',

        [useMediaQuery(MediaQuery.LargeScreen)]: {
          display: 'block',
        },
      })}
    />
  );

  return (
    <div
      css={css({
        position: 'relative',
        overflow: 'hidden',
      })}
      data-testid="audience-section"
    >
      <SectionContainer>
        <div
          css={css({
            left: 0,
            pointerEvents: 'none',
            position: 'absolute',
            top: 200,
            userSelect: 'none',
            zIndex: -1,

            [stackedMQ]: {
              top: 'auto',
              bottom: 0,
            },
          })}
        >
          <SectionAudiencePatternLeft />
        </div>

        <div
          css={css({
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
            top: 0,
            userSelect: 'none',
            zIndex: -1,
          })}
        >
          <SectionAudiencePatternRight />
        </div>

        <SectionTitle
          subTitle={t('pages.home.section-audience-subtitle')}
          title={t('pages.home.section-audience-title')}
        />
        <div
          css={css({
            display: 'grid',
            gap: 60,
            gridTemplateColumns: '1fr 5fr 5fr 1fr',
            marginTop: 50,
            padding: spacing.x12,

            [useMediaQuery(MediaQuery.LargeScreen)]: {
              gridTemplateColumns: '1fr 5fr 1fr',
            },

            [useMediaQuery(MediaQuery.SmallAndMedium)]: {
              gridTemplateColumns: '1fr',
            },
          })}
        >
          {extraLargeSideGap}
          {largeGap}
          <SampleAudienceCard
            borderColorName="pink"
            intro={t('pages.home.section-audience-person-1-intro')}
            photoURL="/static/images/home-person-1-photo.svg"
            title={t('pages.home.section-audience-person-1-title')}
          />
          {largeGap}

          {largeGap}
          <SampleAudienceCard
            borderColorName="blue"
            intro={t('pages.home.section-audience-person-2-intro')}
            photoURL="/static/images/home-person-2-photo.svg"
            subtitle={t('pages.home.section-audience-person-2-subtitle')}
            title={t('pages.home.section-audience-person-2-title')}
          />
          {largeGap}
          {extraLargeSideGap}

          {extraLargeSideGap}
          {largeGap}
          <SampleAudienceCard
            borderColorName="pink"
            intro={t('pages.home.section-audience-person-3-intro')}
            photoURL="/static/images/home-person-3-photo.svg"
            subtitle={t('pages.home.section-audience-person-3-subtitle')}
            title={t('pages.home.section-audience-person-3-title')}
          />
          {largeGap}

          {largeGap}
          <SampleAudienceCard
            borderColorName="blue"
            intro={t('pages.home.section-audience-person-4-intro')}
            photoURL="/static/images/home-person-4-photo.svg"
            subtitle={t('pages.home.section-audience-person-4-subtitle')}
            title={t('pages.home.section-audience-person-4-title')}
          />
          {largeGap}
          {extraLargeSideGap}
        </div>
      </SectionContainer>
    </div>
  );
}
