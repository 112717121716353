import {UserPublicProfile_UserFragment} from './UserPublicProfile_UserFragment';

const USERS: UserPublicProfile_UserFragment[] = [
  {
    id: '10000000021',
    firstName: 'Kei',
    lastName: 'Oki',
    isOneOneMentor: true,
    language: ['ja', 'en', 'zh'],
    profession: {
      company: 'Adobe Japan',
      title: 'GTM Business Manager',
    },
    sns: [
      {
        type: 'linkedin',
        url: 'https://www.linkedin.com/in/keioki/',
      },
    ],
    intro: {
      message:
        '日本、ドイツ、シンガポールにて法人営業やデジタルマーケティングに従事。2022年より現職。趣味は美術鑑賞。',
    },
    photoDownloadUrl: '/static/images/kMASOkQt47/AY3zppt0VH.jpg',
  },
  {
    id: '10000000022',
    firstName: 'Takuya',
    lastName: 'Abe',
    isOneOneMentor: true,
    language: ['ja', 'en'],
    profession: {
      title: '個人事業主',
      company: 'UiPath(前職)',
    },
    sns: [
      {
        type: 'linkedin',
        url: 'https://www.linkedin.com/in/takuya-abe/',
      },
      {
        type: 'facebook',
        url: 'https://www.facebook.com/takuya.abe.332/',
      },
    ],
    intro: {
      message:
        '元楽天株式会社にてマネージャ、元UiPath執行役員プロフェッショナルサービスの責任者。現在は独立し企業アドバイザー等の傍ら起業の準備中。',
    },
    photoDownloadUrl: '/static/images/kMASOkQt47/LZHHFktLi4.jpg',
  },
  {
    id: '10000000023',
    firstName: 'Sha',
    lastName: 'Ma',
    isOneOneMentor: true,
    language: ['en', 'zh', 'ja'],
    profession: {
      company: 'IBM(前職)',
      title: 'Finance Consulting Manager',
    },
    sns: [
      {
        type: 'linkedin',
        url: 'https://www.linkedin.com/in/ma-sha/',
      },
    ],
    intro: {
      message:
        'ファイナンスDX戦略、プロセス設計、システム構築を担当。中国からのグローバルな転職経験を持つ。趣味はバーベル。',
    },
    photoDownloadUrl: '/static/images/kMASOkQt47/fxOqOUNuIO.jpg',
  },
  {
    id: '10000000024',
    firstName: 'Massimiliano',
    lastName: 'Schilliro',
    isOneOneMentor: true,
    language: ['ja', 'en', 'it'],
    profession: {
      company: 'Amazon Japan',
      title: 'Program Manager',
    },
    sns: [
      {
        type: 'linkedin',
        url: 'https://www.linkedin.com/in/massimilianoschilliro/',
      },
    ],
    intro: {
      message:
        '日本でプログラムとプロジェクトマネージャーとして国内外の企業で働いた。2023年より現職。趣味は写真とビデオ。',
    },
    photoDownloadUrl: '/static/images/kMASOkQt47/gKZASkyYTY.jpg',
  },
];

export default USERS;
