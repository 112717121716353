import formatDayOfMonth from '../../time/formatDayOfMonth';
import formatMonth from '../../time/formatMonth';
import {CSSStyle} from '../../types/CSSStyles';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';
import BodyText from '../design_system/typography/BodyText';
import DetailsText from '../design_system/typography/DetailsText';
import HeadingText from '../design_system/typography/HeadingText';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export type SquareMonthDateSize = 60 | 80;

export interface Props {
  readonly size: SquareMonthDateSize;
  readonly timestampUTCSeconds: number;
  readonly rootStyle?: CSSStyle;
}

export default function SquareMonthDate(props: Props): JSX.Element {
  const {rootStyle, size, timestampUTCSeconds} = props;
  const theme = useAppTheme();

  let monthElement: JSX.Element;
  let dateElement: JSX.Element;
  switch (size) {
    case 80:
      monthElement = (
        <BodyText size="S">{formatMonth(timestampUTCSeconds)}</BodyText>
      );
      dateElement = (
        <HeadingText
          isHeading={false}
          size="L"
        >
          {formatDayOfMonth(timestampUTCSeconds)}
        </HeadingText>
      );
      break;
    case 60:
      monthElement = (
        <DetailsText>{formatMonth(timestampUTCSeconds)}</DetailsText>
      );
      dateElement = (
        <HeadingText
          isHeading={false}
          size="M"
        >
          {formatDayOfMonth(timestampUTCSeconds)}
        </HeadingText>
      );
  }

  return (
    <Flex
      sx={[
        css({
          alignItems: 'center',
          backgroundColor: theme.palettes.background.neutralSubdued.default,
          borderRadius: '12px',
          boxSizing: 'border-box',
          flexDirection: 'column',
          height: size,
          justifyContent: 'center',
          width: size,

          [useMediaQuery(MediaQuery.SmallAndMedium)]: {
            padding: 8,
          },
        }),
        rootStyle,
      ]}
    >
      {monthElement}
      {dateElement}
    </Flex>
  );
}
