import HomePageHeroPattern from './HomePageHeroPattern';
import ClientOnlyContent from '../../../lib/components/ClientOnlyContent';
import SignupCTAButton from '../../../lib/components/design_system/Button/SignupCTAButton';
import useAppTheme from '../../../lib/components/design_system/hooks/useAppTheme';
import useMediaQuery from '../../../lib/components/design_system/hooks/useMediaQuery';
import {MediaQuery} from '../../../lib/components/design_system/hooks/useMediaQueryGetter';
import {ContentColor} from '../../../lib/components/design_system/theme/useContentPalette';
import HeadingText from '../../../lib/components/design_system/typography/HeadingText';
import Typography, {
  Size,
} from '../../../lib/components/design_system/typography/Typography';
import MarketingHero from '../../../lib/components/layout/hero/MarketingHero';

import {css} from '@emotion/react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

export default function HomePageHero(): JSX.Element {
  const {t} = useTranslation();
  const {spacing} = useAppTheme();

  return (
    <MarketingHero
      background={
        <div>
          <video
            autoPlay={true}
            controls={false}
            loop={true}
            muted={true}
            playsInline={true}
            poster="/static/images/hero-poster.jpg"
            src="/static/images/hero-video.mp4"
          />

          <div
            css={css({
              opacity: 0.4,
              position: 'absolute',
              right: 0,
              top: 0,
              [useMediaQuery(MediaQuery.SmallScreen)]: {
                transform: 'translateX(55%)',
              },
              [useMediaQuery(MediaQuery.MediumScreen)]: {
                transform: 'translateX(40%)',
              },
            })}
          >
            <HomePageHeroPattern />
          </div>
        </div>
      }
      copy={
        <div
          css={css({
            maxWidth: 700,
            width: '100%',
          })}
          data-testid="home-page-hero"
        >
          <HeadingText
            color={ContentColor.PRIMARY_CONTRAST}
            size="XXL"
          >
            <Trans i18nKey="pages.home.hero-copy-title" />
          </HeadingText>
          <Typography
            color={ContentColor.PRIMARY_CONTRAST}
            rootStyle={css({
              marginTop: spacing.x20,
            })}
            size={Size.L}
          >
            {t('pages.home.hero-copy-subtitle')}
          </Typography>
          <div
            css={css({
              display: 'inline-block',
              height: 20,
              marginTop: spacing.x32,
            })}
          >
            <ClientOnlyContent>
              <SignupCTAButton />
            </ClientOnlyContent>
          </div>
        </div>
      }
    />
  );
}
