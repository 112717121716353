import FAQCard from './FAQCard';
import useI18nData from '../../hooks/useI18nData';
import useAppTheme from '../design_system/hooks/useAppTheme';
import Flex from '../layout/flex/Flex';

import {css} from '@emotion/react';
import React from 'react';

export interface QNA {
  readonly answer: string;
  readonly question: string;
}

export default function SelectedFAQ(): JSX.Element {
  const {spacing} = useAppTheme();
  const data = useI18nData<QNA[]>('pages.home.section-faq.data');

  return (
    <Flex
      sx={css({
        flexDirection: 'column',
        rowGap: spacing.x24,
      })}
    >
      {data.map((Item, i) => (
        <FAQCard
          data={Item}
          key={i}
        />
      ))}
    </Flex>
  );
}
