import UpcomingEventCard from './UpcomingEventCard';
import {useUpcomingEventsQuery} from '../../../pages/HomePage/__generated__/HomePage.graphql';
import {linkToMarketingWithParams} from '../../url/linkToMarketing';
import Button from '../design_system/Button';
import {ButtonColor} from '../design_system/Button/options';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQueryGetter, {
  MediaQuery,
} from '../design_system/hooks/useMediaQueryGetter';
import {ContentColor} from '../design_system/theme/useContentPalette';
import Typography, {Size} from '../design_system/typography/Typography';
import getEventStatus from '../event/getEventStatus';

import {css} from '@emotion/react';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function UpcomingEvent(): JSX.Element | null {
  const {t} = useTranslation();
  const {data, loading: isLoading} = useUpcomingEventsQuery();
  const {palettes, spacing} = useAppTheme();
  const getMQ = useMediaQueryGetter();

  let eventCard = null;
  if (isLoading) {
    // Need better handling
    eventCard = <div>{t('common-strings.loading')}</div>;
  }

  const session = data?.groupSessions.edges?.[0]?.node;
  if (session == null) {
    return null;
  }
  const sessionStatus = getEventStatus(session);
  const sectionTitle =
    sessionStatus === 'pending'
      ? t('pages.home.section-upcoming-events-title')
      : t('pages.home.section-upcoming-events-past-event-title');

  eventCard = <UpcomingEventCard session={session} />;

  const extraLargeScreenSideGap = (
    <div
      css={css({
        display: 'none',

        [getMQ(MediaQuery.ExtraLargeScreen)]: {
          display: 'block',
        },
      })}
    />
  );

  return (
    <div
      css={css({
        alignItems: 'center',
        justifyItems: 'center',
        backgroundColor: palettes.background.accent.default,
        display: 'grid',
        columnGap: spacing.x32,
        gridTemplateColumns: 'repeat(12, 1fr)',
        paddingTop: spacing.x48,
        paddingBottom: spacing.x48,

        [getMQ(MediaQuery.NonExtraLargeScreen)]: {
          gridTemplateColumns: '6fr',
          gridTemplateRows: 'repeat(3, auto)',
          rowGap: spacing.x20,
        },
      })}
      data-testid="upcoming-events-section"
    >
      {extraLargeScreenSideGap}

      <Typography
        color={ContentColor.PRIMARY_CONTRAST}
        rootStyle={css({
          gridColumn: '2 / span 2',

          [getMQ(MediaQuery.NonExtraLargeScreen)]: {
            gridColumn: '1 / span 6',
          },
        })}
        size={Size.L}
        variant="div"
      >
        {sectionTitle}
      </Typography>

      <div
        css={css({
          gridColumn: '4 / span 6',
          boxSizing: 'border-box',
          paddingRight: spacing.x20,
          paddingTop: spacing.x16,
          paddingBottom: spacing.x16,
          borderRight: `2px solid ${palettes.background.neutral.disabled}`,
          borderBottom: 'none',

          [getMQ(MediaQuery.NonExtraLargeScreen)]: {
            gridColumn: '1 / span 6',
            borderRight: 'none',
            borderBottom: `2px solid ${palettes.background.neutral.disabled}`,
            paddingLeft: spacing.x20,
          },
        })}
      >
        {eventCard}
      </div>

      <div
        css={css({
          gridColumn: '10 / span 2',

          [getMQ(MediaQuery.NonExtraLargeScreen)]: {
            gridColumn: '1 / span 6',
          },
        })}
      >
        <Button
          color={ButtonColor.SECONDARY_CONTRAST}
          label={t('pages.home.section-upcoming-events-cta-button-label')}
          sx={css({
            display: 'block',
          })}
          to={linkToMarketingWithParams('/event/:id', {id: session.id})}
        />
      </div>

      {extraLargeScreenSideGap}
    </div>
  );
}
